import React, { useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { sendGTMEvent } from '@next/third-parties/google';
import { Col, Container, Row } from 'react-bootstrap';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { ImageHelpers } from 'helpers/imageHelpers';
import { useAccount } from 'frontastic';
import styles from './PressRoomListPage.module.scss';
import { IPressRoomListPageProps } from './types/IPressRoomListPageProps';
import { DateHelpers } from '../../../helpers/dateHelpers';
import { useFormat } from '../../../helpers/hooks/useFormat';
import { useLocale } from '../../../helpers/hooks/useLocale';
import PageFilter, { colorThemes, widths } from '../../commercetools-ui/organisms/page-filter';
import PageHeader from '../../commercetools-ui/organisms/page-header';
import PageSearch, { searchTypes } from '../../commercetools-ui/organisms/page-search';
import Pagination from '../../nikon-ui/atoms/pagination';
import SkipContent from '../atoms/SkipContent/SkipContent';
import OneToFourColumn from '../ContentBlocks/OneToFourColumn';
import Footer from '../Footer';
import Header from '../Header';
import PromoBanner from '../PromoBanner';

const PressRoomListPage = ({ data }: IPressRoomListPageProps) => {
  const router = useRouter();
  const language = useLocale().localizationInfo.language;
  const { formatMessage } = useFormat({ name: 'press-room-list-page' });
  const { account, accountLoading } = useAccount();

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading) {
      sendGTMEvent({
        event: 'page_view',
        pageName: PAGE_VIEW_EVENTS.PRESS_ROOM_LIST.NAME,
        pageCategory: PAGE_VIEW_EVENTS.PRESS_ROOM_LIST.CATEGORY,
        pageType: PAGE_VIEW_EVENTS.PRESS_ROOM_LIST.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading]);

  const pageTitle = formatMessage({ id: 'title', defaultMessage: 'Press Room' });
  const resultsText = `${data.pressRoomArticleTotal} ${formatMessage({
    id: 'results_text',
    defaultMessage: 'Results',
  })}`;
  const articleTypes: any = [
    formatMessage({ id: 'type_corporate', defaultMessage: 'corporate' }),
    formatMessage({ id: 'type_products', defaultMessage: 'products' }),
  ];
  const defaultFilterOption = formatMessage({
    id: 'default_option',
    defaultMessage: 'all topics',
  });
  const searchPlaceHolder = formatMessage({ id: 'search_place_holder', defaultMessage: 'search' });
  const noResultsHeading = formatMessage({ id: 'no_results.heading', defaultMessage: 'No Matches' });
  const noResultsMessage = formatMessage({
    id: 'no_results.message',
    defaultMessage: 'We couldn’t find any articles that matched your search. Try again.',
  });
  const resultsOffset = router.query.offset ? parseInt(router.query.offset as any) : 0;
  const resultsLimit = 12;
  const noResultsOutput = (
    <Container className={styles.no_results_wrapper}>
      <h2>{noResultsHeading}</h2>
      <p>{noResultsMessage}</p>
    </Container>
  );

  const articleList = data?.pressRoomArticles || [];

  return (
    <div data-testid="press-room-list-page" className="press-room-list-page" key={'press-room-list-page'}>
      <SkipContent />
      {data.promoBanner && <PromoBanner data={data.promoBanner} />}
      {data.header && <Header data={data.header} />}

      <main data-testid="press-room-list-page-main-content" id="nkn-main-content" tabIndex={-1}>
        <PageHeader
          backgroundImage={`${data?.headerDesktopImage?.url}`}
          backgroundImageProps={'no-repeat center center #000'}
          backgroundImageMobile={`${data?.headerMobileImage?.url}`}
          backgroundImagePropsMobile={'no-repeat center center #000'}
          className={styles.page_header}
          headerBarClassName={styles.filter_search_bar}
          pageTitle={pageTitle}
          pageTitleClassName={styles.page_header_title}
        >
          <section className={styles.filter_search_bar}>
            <Container>
              <Col sm={12} md={4} lg={6} className={styles.results_text}>
                {resultsText}
              </Col>
              <PageFilter
                uid={'pressroom-filter'}
                filterOptions={articleTypes}
                queryVar={'topic'}
                variant={'small'}
                className={`${styles.pressroom_filter}`}
                colorTheme={colorThemes.dark}
                defaultOption={defaultFilterOption}
                columns={{ sm: 12, md: 3, lg: 2 }}
                width={widths.fit}
              ></PageFilter>
              <PageSearch
                uid={'press-room-page-search'}
                id={'press-room-page-search'}
                searchType={searchTypes.filter}
                placeHolderText={searchPlaceHolder}
                showSearchButton={true}
                className={`${styles.pressroom_search}`}
                searchOptions={{ queryVar: 'q' }}
                columns={{ sm: 12, md: 5, lg: 4 }}
              ></PageSearch>
            </Container>
          </section>
        </PageHeader>
        <Container className={styles.pressroom_list_page_content}>
          <Row>
            {articleList.length === 0 && noResultsOutput}
            {articleList.map((article) => {
              const formattedDate = DateHelpers.intlFormatDate(article.articleReleaseDate, 'long', language, 'UTC');
              const articleImageObject = article.sidebarContent?.digitalPhotoGalleryReference[0]?.digitalPhotoGroup[0];
              const articleImage = articleImageObject?.productImage?.url ? (
                <div className={styles.image_wrapper}>
                  <img
                    src={ImageHelpers.modifiedContentStackImageUrl(articleImageObject.productImage?.url, '228')}
                    alt={''}
                  />
                </div>
              ) : undefined;

              return (
                <Col xs={12} key={`article-${article.uid}`} className={styles.article_wrapper}>
                  <Link href={`/press-room/${article.slug}`}>
                    <a href={`/press-room/${article.slug}`} className={styles.article}>
                      <div className={styles.article_inner_wrapper}>
                        <div className={styles.article_info}>
                          <p className={styles.date}>{formattedDate}</p>
                          <h3 className={styles.title}>{article.articleTitle}</h3>
                          <p className={styles.article_text}>{article.articleSubtitle}</p>
                        </div>
                        {articleImage}
                      </div>
                    </a>
                  </Link>
                </Col>
              );
            })}
          </Row>
          <div className={styles.pagination}>
            <Pagination
              pagination={{ total: data.pressRoomArticleTotal, limit: resultsLimit, offset: resultsOffset }}
            />
          </div>
          {Boolean(data?.pressResources?.length) && (
            <Row className={styles.additional_content_outer_wrapper}>
              <Col xs={12}>
                <h2 className={styles.additional_content_header}>
                  {formatMessage({ id: 'press.resources', defaultMessage: 'Press Resources' })}
                </h2>
              </Col>
              <Col xs={12} className={styles.additional_content_wrapper}>
                {data.pressResources.map((item) => (
                  <OneToFourColumn data={item} key={item.uid} className={`${styles.additional_content}`} />
                ))}
              </Col>
            </Row>
          )}
        </Container>
      </main>
      {data.footer && <Footer data={data.footer} />}
    </div>
  );
};

export default PressRoomListPage;
