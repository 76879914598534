import React, { useEffect } from 'react';
import Link from 'next/link';
import { sendGTMEvent } from '@next/third-parties/google';
import classNames from 'classnames';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import styles from './PressRoomArticle.module.scss';
import { IPressRoomArticleProps } from './types/IPressRoomArticleProps';
import { appLocales } from '../../../helpers/constants/appLocales';
import { DateHelpers } from '../../../helpers/dateHelpers';
import { useFormat } from '../../../helpers/hooks/useFormat';
import { useLocale } from '../../../helpers/hooks/useLocale';
import GalleryLightbox from '../../commercetools-ui/organisms/gallery-lightbox';
import PageSearch, { searchTypes } from '../../commercetools-ui/organisms/page-search';
import SkipContent from '../atoms/SkipContent/SkipContent';

const PressRoomArticle = ({ data }: IPressRoomArticleProps) => {
  const { account, accountLoading } = useAccount();
  const language = useLocale().localizationInfo.language;
  const appLocale = useLocale().localizationInfo.appLocale;
  const { formatMessage } = useFormat({ name: 'press-room-list-page' });
  const isBrazil = appLocale === appLocales.PT_BR;

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading && data?.articleTitle) {
      sendGTMEvent({
        event: 'page_view',
        pageName: data.articleTitle,
        pageCategory: data.articleTopic,
        pageType: PAGE_VIEW_EVENTS.PRESS_ROOM_ARTICLE.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading, data?.articleTitle]);

  const contactButtonText = formatMessage({
    id: 'contact_button_text',
    defaultMessage: 'contact',
  });
  const searchPlaceHolder = formatMessage({
    id: 'search_place_holder_article',
    defaultMessage: 'search the press room',
  });
  const downLoadText = formatMessage({
    id: 'download',
    defaultMessage: 'download',
  });
  const linksText = formatMessage({
    id: 'press_links',
    defaultMessage: 'links and press kit',
  });
  const formattedDate = DateHelpers.intlFormatDate(data?.articleReleaseDate, 'long', language, 'UTC');
  const mainCols =
    data?.sidebarContent?.digitalPhotoGalleryReference.length === 0 ? { md: 12, lg: 12 } : { md: 12, lg: 8 };

  return (
    <div data-testid="press-room-article-page" className={styles.press_article_page}>
      <SkipContent />
      <main data-testid="press-room-list-page-main-content" id="nkn-main-content" tabIndex={-1}>
        <Container>
          {!isBrazil && (
            <Row className={styles.article_header}>
              <Col sm={2} md={8} lg={8} className={styles.button_wrapper}>
                <Link href={'/content/press-contact'}>
                  <Button className={styles.contact_us_btn}>{contactButtonText}</Button>
                </Link>
              </Col>
              <Col className={styles.search_wrapper} sm={10} md={4} lg={3}>
                <PageSearch
                  uid={'press-room-article-search'}
                  id={'press-room-article-search'}
                  searchType={searchTypes.redirect}
                  placeHolderText={searchPlaceHolder}
                  showSearchButton={false}
                  className={`${styles.pressroom_search}`}
                  searchOptions={{ queryVar: 'q' }}
                  redirectPath={'/press-room'}
                  columns={{ sm: 12, md: 12, lg: 12 }}
                ></PageSearch>
              </Col>
            </Row>
          )}
          <Row className={isBrazil ? 'mt-5' : ''}>
            <Col {...mainCols} className={styles.article_main_content}>
              <p className={styles.article_main_content_date}>{formattedDate}</p>
              <h1 className={styles.article_main_content_title}>{data?.articleTitle}</h1>
              <p className={styles.article_main_content_subtitle}>{data?.articleSubtitle}</p>
              <p className={styles.article_main_content_location}>{`${data?.articleLocation} \u2014`}</p>
              <div
                className={styles.article_main_content_body}
                dangerouslySetInnerHTML={{ __html: data?.articleContent }}
                key={new Date().getTime()}
              ></div>
            </Col>
            <Col sm={12} md={4} lg={3}>
              {data?.sidebarContent?.digitalPhotoGalleryReference.map((gallery) => {
                const galleryTitle =
                  gallery?.digitalPhotoGroup.length > 1
                    ? `${gallery?.galleryTitle} Images`
                    : `${gallery?.galleryTitle} Image`;
                const downLoadButton = (
                  <div className={styles.download_btn_wrapper}>
                    <Link href={gallery?.downloadURL}>
                      <a className={classNames(styles['download_btn'], 'btn btn-primary')} target="_blank">
                        {downLoadText}
                      </a>
                    </Link>
                  </div>
                );
                if (gallery?.digitalPhotoGroup.length !== 0) {
                  return (
                    <div className={styles.gallery_wrapper} key={gallery.uid}>
                      <p className={styles.gallery_title}>{galleryTitle}</p>
                      <GalleryLightbox
                        uid={gallery.uid}
                        galleryImages={gallery?.digitalPhotoGroup as any}
                        className={styles.gallery_images}
                        galleryStyles={{
                          anchorStyle: `${styles.gallery_image}`,
                          imgWrapperStyle: `${styles.img_wrap}`,
                        }}
                      />
                      {gallery?.downloadURL && downLoadButton}
                    </div>
                  );
                }
              })}
              {Boolean(data?.sidebarContent?.linksGroup?.length) && <p className={styles.press_links}>{linksText}</p>}
              {data?.sidebarContent?.linksGroup.map((link, index) => {
                return (
                  <>
                    <Link href={link?.linkUrl} key={index}>
                      <a className={styles.press_link}>{link?.linkCopyText}</a>
                    </Link>
                    <br />
                  </>
                );
              })}
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default PressRoomArticle;
