import { useEffect } from 'react';
import Head from 'next/head';
import { sendGTMEvent } from '@next/third-parties/google';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import { ITradeInFormPageProps } from './types/ITradeInFormPageProps';
import SkipContent from '../atoms/SkipContent/SkipContent';
import ContentBlocks from '../ContentBlocks';
import Footer from '../Footer';
import Header from '../Header';
import PromoBanner from '../PromoBanner';

const TradeInFormPage = ({ data }: ITradeInFormPageProps) => {
  const { account, accountLoading } = useAccount();

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading) {
      sendGTMEvent({
        event: 'page_view',
        pageName: PAGE_VIEW_EVENTS.TRADE_IN_FORM.NAME,
        pageCategory: PAGE_VIEW_EVENTS.TRADE_IN_FORM.CATEGORY,
        pageType: PAGE_VIEW_EVENTS.TRADE_IN_FORM.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading]);

  const metaTitle = data?.metaData?.metaTitle ? data?.metaData?.metaTitle : data?.title;
  const metaDescription = data?.metaData?.metaDescription ? data?.metaData?.metaDescription : '';
  const metaKeywords = data?.metaData?.metaKeywords ? data?.metaData?.metaKeywords : '';
  const metaOGImage = data?.metaData?.metaImage?.url ? data?.metaData?.metaImage?.url : '';

  return (
    <div data-testid="trade_in_form_page" className="nikon-trade-in-form-page">
      <SkipContent />
      <Head>
        {Boolean(metaTitle) && <title>{metaTitle}</title>}
        {Boolean(metaDescription) && <meta name="description" content={metaDescription} />}
        {Boolean(metaKeywords) && <meta name="keywords" content={metaKeywords} />}
        {Boolean(metaOGImage) && <meta property="og:image" content={metaOGImage} />}
      </Head>

      {data.promoBanner && <PromoBanner data={data.promoBanner} />}
      {data.header && <Header data={data.header} />}
      <main data-testid="trade-in-form-page-main-content" id="nkn-main-content" tabIndex={-1}>
        <ContentBlocks data={data.contentBlocks} />
      </main>
      {data.footer && <Footer data={data.footer} />}
    </div>
  );
};
export default TradeInFormPage;
