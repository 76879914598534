import { useEffect } from 'react';
import Head from 'next/head';
import { sendGTMEvent } from '@next/third-parties/google';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import { ICategoryLandingPageProps } from './types/ICategoryLandingPageProps';
import SkipContent from '../atoms/SkipContent/SkipContent';
import CLPBrow from '../CLPBrow';
import ContentBlocks from '../ContentBlocks';
import Footer from '../Footer';
import Header from '../Header';
import PromoBanner from '../PromoBanner';

const CategoryLandingPage = ({ data }: ICategoryLandingPageProps) => {
  const { formatMessage } = useFormat({ name: 'categories' });
  const { account, accountLoading } = useAccount();
  const {
    metaData: { metaDescription, metaImage, metaKeywords },
  } = data;

  const title = data?.categorySlug.endsWith('/overview')
    ? formatMessage({
        id: 'overview.meta.title',
        defaultMessage: '{title} | Nikon USA',
        values: { title: data?.title || '' },
      })
    : formatMessage({
        id: 'meta.title',
        defaultMessage: 'Shop All Nikon {category} | Nikon USA',
        values: { category: data?.title || '' },
      });

  useEffect(() => {
    if (window !== undefined && !accountLoading && data?.title) {
      sendGTMEvent({
        event: 'page_view',
        pageName: data?.title,
        pageCategory: data.categorySlug.replace('/', ''),
        pageType: PAGE_VIEW_EVENTS.CATEGORY_LANDING.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading, data]);

  return (
    <>
      <Head>
        {title && <title>{title}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {metaImage && <meta property="og:image" content={metaImage.url} />}
      </Head>
      <SkipContent />
      <div data-testid="category_landing_page" className="nikon-category-landing-page">
        {data.promoBanner && <PromoBanner data={data.promoBanner} />}
        <Header data={data.header} />
        {data.clpBrowTabsMenu && <CLPBrow data={data.clpBrowTabsMenu} />}
        <main
          className="category-landing-page-main-content"
          data-testid="category-landing-page-main-content"
          id="nkn-main-content"
          tabIndex={-1}
        >
          <ContentBlocks data={data.contentBlocks} />
        </main>
        <Footer data={data.footer} />
      </div>
    </>
  );
};

export default CategoryLandingPage;
